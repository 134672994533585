'use strict';


function showTestimonialsList(elem) {
    Array.from(document.getElementsByClassName("testimonial-section__show-testimonials"))
        .forEach((e) => e.classList.remove("testimonial-section__show-testimonials--selected"));
    elem.labels[0].classList.add("testimonial-section__show-testimonials--selected");


    Array.from(document.getElementsByClassName("testimonials"))
        .forEach((e) => e.classList.add("testimonials--hidden"));
    document.getElementById(elem.dataset.testimonialsSectionId)
        .classList.remove("testimonials--hidden");

}

scriptSync("utils", () => {

    addEventAll("button.testimonial--overflow__button",
        (function (e) {
            const list = e.target.parentNode.classList;
            if (!list.contains("testimonial--overflow__open")) {
                list.add("testimonial--overflow__open");
            } else {
                list.remove("testimonial--overflow__open");
            }
        })
    );

    addEventAll("input[name='show-testimonials']", (e) => showTestimonialsList(e.target), 'change');

    addEventAll("input[name='show-testimonials']",
        (event) => {
            if (event.target.matches(":focus-visible")) {
                document.getElementsByName("show-testimonials")
                    .forEach((e) => e.classList.add("testimonial-section__controls__input--group-focus"));
            }
        }, 'focus');

    addEventAll("input[name='show-testimonials']",
        () => document.getElementsByName("show-testimonials")
            .forEach((e) => e.classList.remove("testimonial-section__controls__input--group-focus")), 'blur');
});

showTestimonialsList(document.querySelector("input[name='show-testimonials']:checked"));


